import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import Layout from './components/Layout';
import Loading from './components/Loading';
import { useParams } from 'react-router-dom'; // Import useParams to get the recordId from the route
import ReactPlayer from 'react-player'; // Import ReactPlayer for better video support

const pb = new PocketBase('https://eerotika.sk'); // Initializing PocketBase

const Player = () => {
  const { recordId } = useParams(); // Get the recordId from the URL parameters
  const [mediaFile, setMediaFile] = useState(null); // State to hold the file URL
  const [fileType, setFileType] = useState(null); // State to hold the file type (image or video)
  const [isLoading, setIsLoading] = useState(true); // State for loading spinner

  useEffect(() => {
    if (!recordId) {
      console.error('Missing recordId');
      return;
    }

    // Fetch file from PocketBase
    const fetchFile = async () => {
      try {
        console.log(`Fetching record with id: ${recordId}`); // Log recordId
        const record = await pb.collection('user_products').getOne(recordId);
        const fileUrl = pb.getFileUrl(record, record.file); // Construct file URL
        console.log(`Attempting to load file: ${record.file}`); // Log file name
        console.log(`File URL: ${fileUrl}`); // Log full URL

        setMediaFile(fileUrl);

        // Determine file type based on extension
        const fileExtension = record.file.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'].includes(fileExtension)) {
          setFileType('image');
        } else if (['mp4', 'mov', 'avi', 'mkv', 'webm'].includes(fileExtension)) {
          setFileType('video');
        }
      } catch (error) {
        console.log('Error fetching file:', error);
      } finally {
        setIsLoading(false); // Stop loading when fetch completes
      }
    };

    fetchFile();
  }, [recordId]);

  return (
    <Layout>
      {/* Display Loading Spinner */}
      <Loading isLoading={isLoading} />

      {/* Content */}
      <div className="flex flex-col justify-center items-center min-h-screen p-4">
        {mediaFile ? (
          fileType === 'image' ? (
            <img src={mediaFile} alt="Media" className="max-w-full h-auto rounded-lg shadow-lg" />
          ) : fileType === 'video' ? (
            <div className="w-full max-w-lg">
              <ReactPlayer
                url={mediaFile} // Use ReactPlayer for better video support (including .mov)
                controls
                width="100%"
                height="auto"
                className="rounded-lg shadow-lg"
              />
              <a
                href={mediaFile}
                download
                className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
              >
                Download Video
              </a>
            </div>
          ) : (
            <p>Unsupported file type</p>
          )
        ) : (
          !isLoading && <p>Loading...</p>
        )}
      </div>
    </Layout>
  );
};

export default Player;
