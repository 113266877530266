import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Layout from './components/Layout';
import { CartContext } from './components/CartContext';

const Cart = () => {
  const { cart, updateQuantity, totalPrice, createPayment } = useContext(CartContext);

  const handleQuantityChange = (itemId, newQuantity) => {
    // Ensure quantity can't go below 0
    const validQuantity = newQuantity < 0 ? 0 : newQuantity;
    updateQuantity(itemId, validQuantity);
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6 text-white">Váš košík</h2>

        {cart.length > 0 ? (
          <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <ul className="space-y-4">
              {cart.map((item) => (
                <li key={item.id} className="flex justify-between items-center bg-gray-700 p-3 rounded-md">
                  <div>
                    <h3 className="text-xl font-bold text-white">{item.title}</h3>
                    <p className="text-sm text-gray-400">
                      Cena: €{item.price.toFixed(2)}
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      className="text-red-600 text-xl font-bold"
                      onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                    >
                      -
                    </button>
                    <span className="text-white">{item.quantity}</span>
                    <button
                      className="text-green-600 text-xl font-bold"
                      onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                    >
                      +
                    </button>
                  </div>
                </li>
              ))}
            </ul>

            <div className="mt-6">
              <p className="text-white font-bold text-2xl">
                Celková suma: €{totalPrice.toFixed(2)}
              </p>
              <Link
                to='/checkout'
                className="inline-block mt-4 bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700"
              >
                Prejsť na platbu
              </Link>
            </div>
          </div>
        ) : (
          <p className="text-white">Váš košík je prázdny.</p>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
