import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentRequestButtonElement, CardElement } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import CardComponent from './components/CardComponent';
import Loading from './components/Loading';
import Layout from './components/Layout';
import PocketBase from 'pocketbase'; // Import PocketBase client

const stripePromise = loadStripe('pk_live_51Q0RiFLt4eCpxGB2FRE4IsMvlwyAxUky2M819OaVym3eBwJI0aJ9Gko4FuJNxmkm7m5ItmnjMxm1723SPs1exrqf00SzN4QdUP', {
  locale: 'sk',
});

const pb = new PocketBase('https://eerotika.sk'); // Initialize PocketBase client

const CustomCheckoutForm = () => {
  const { product_id } = useParams(); // Get product_id from the URL
  const stripe = useStripe();
  const elements = useElements();

  const [product, setProduct] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [loading, setLoading] = useState(false); // Control loading state
  const [error, setError] = useState('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canUsePaymentRequest, setCanUsePaymentRequest] = useState(false);

  // Fetch product data from PocketBase
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRecord = await pb.collection('user_products').getOne(product_id);
        setProduct(productRecord);
      } catch (err) {
        console.log('Nepodarilo sa načítať detaily produktu.');
      }
    };
    fetchProductDetails();
  }, [product_id]);

  useEffect(() => {
    if (!stripe || !product) return;

    // Step 1: Create a payment request object
    const pr = stripe.paymentRequest({
      country: 'SK',
      currency: 'eur',
      total: {
        label: product.name,
        amount: product.price * 100, // price in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Step 2: Check if the Payment Request Button is available
    pr.canMakePayment().then((result) => {
      if (result) {
        setCanUsePaymentRequest(true);
        setPaymentRequest(pr);
      }
    });

    // Handle payment request event (e.g., Apple Pay or Google Pay)
    pr.on('paymentmethod', async (e) => {
      setLoading(true);
      try {
        const { clientSecret } = await createPaymentIntent(); // Create payment intent on the server

        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: e.paymentMethod.id,
        });

        if (confirmError) {
          e.complete('fail');
          setError(`Platba zlyhala: ${confirmError.message}`);
        } else {
          e.complete('success');
          if (paymentIntent.status === 'succeeded') {
            await updateSellerBalance(product);
            window.location.replace('/payment-done');
          }
        }
      } catch (err) {
        setError('Platba zlyhala.');
        e.complete('fail');
      } finally {
        setLoading(false);
      }
    });
  }, [stripe, product]);

  const createPaymentIntent = async () => {
    const paymentData = {
      amount: product.price * 100, // Amount in cents
      email,
      name,
      product_id,
    };

    const response = await fetch('https://api.eerotika.sk/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentData),
    });

    if (!response.ok) {
      throw new Error('Chyba pri vytváraní platby.');
    }

    return await response.json();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !product) return;

    const billingDetails = {
      name,
      email,
    };

    if (!product.virtual) {
      billingDetails.address = {
        line1: addressLine1,
        city,
        country,
        postal_code: postalCode,
      };
    }

    try {
      const { clientSecret } = await createPaymentIntent();

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: billingDetails,
        },
      });

      if (paymentResult.error) {
        setError(`${paymentResult.error.message}`);
      } else {
        await updateSellerBalance(product);
        if (paymentResult.paymentIntent.status === 'succeeded') {
          window.location.replace(product.virtual ? `/player/${product_id}` : '/payment-done');
        }
      }
    } catch (err) {
      setError('Platba zlyhala. Skúste to znova.');
    } finally {
      setLoading(false);
    }
  };

  const updateSellerBalance = async (product) => {
    try {
      const sellerId = product.owner;
      const sellerRecord = await pb.collection('users').getOne(sellerId);
      const currentBalance = sellerRecord.balance || 0;
      const productPriceWithoutVAT = product.price * 0.80;
      const netAmount = productPriceWithoutVAT * 0.90;
      const newBalance = currentBalance + netAmount;

      await pb.collection('users').update(sellerId, { balance: newBalance });
      console.log(`Aktualizovaný balance pre používateľa ${sellerId}: ${newBalance}`);
    } catch (err) {
      console.error('Chyba pri aktualizácii balance majiteľa produktu:', err.message);
    }
  };

  if (!product) {
    return <Loading isLoading={true} />;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Loading isLoading={loading} />
      {error && <p className="text-red-600">{error}</p>}

      {canUsePaymentRequest && (
        <div>
          <p className="text-white">Alebo použite Apple Pay alebo Google Pay:</p>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Zadajte váš email"
          className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
        />
      </div>

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-white">Meno a priezvisko</label>
        <input
          id="name"
          type="text"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Zadajte vaše meno a priezvisko"
          className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
        />
      </div>

      {!product.virtual && (
        <>
          <div>
            <label htmlFor="addressLine1" className="block text-sm font-medium text-white">Dodacia adresa (riadok 1)</label>
            <input
              id="addressLine1"
              type="text"
              required
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              placeholder="Zadajte adresu"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>

          <div>
            <label htmlFor="city" className="block text-sm font-medium text-white">Mesto</label>
            <input
              id="city"
              type="text"
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Zadajte vaše mesto"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>

          <div>
            <label htmlFor="country" className="block text-sm font-medium text-white">Krajina</label>
            <select
              id="country"
              required
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            >
              <option value="">Vyberte krajinu</option>
              <option value="SK">Slovensko</option>
              <option value="CZ">Česko</option>
            </select>
          </div>

          <div>
            <label htmlFor="postalCode" className="block text-sm font-medium text-white">PSČ</label>
            <input
              id="postalCode"
              type="text"
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              placeholder="Zadajte PSČ"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
        </>
      )}

      <CardComponent />

      <div>
        <p className="text-lg text-white">
          Celková suma: {product.price.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR' })}
        </p>
      </div>

      <button
        type="submit"
        disabled={loading || !stripe}
        className={`w-full py-2 text-center rounded-md font-semibold ${loading ? 'bg-gray-600' : 'bg-red-600 hover:bg-red-700'} transition duration-300 ease-in-out`}
      >
        {loading ? 'Spracováva sa...' : 'Zaplatiť teraz'}
      </button>
    </form>
  );
};

const CustomCheckout = () => (
  <Layout>
    <div className="max-w-md mx-auto mt-8 p-6 bg-gray-900 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">Platba</h2>
      <Elements stripe={stripePromise}>
        <CustomCheckoutForm />
      </Elements>
    </div>
  </Layout>
);

export default CustomCheckout;
