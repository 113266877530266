import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { LoginForm, SignUpForm } from "./Auth";
import Home from "./Home";
import Dashboard from "./Dashboard";
import AgeVerificationPopup from "./ UIComponents";
import Profile from "./Profile";
import Settings from "./Settings";
import NotFound from "./components/NotFound";
import ScrollToTop from "./ScrollToTop";
import { CartProvider } from "./components/CartContext"; // Import CartProvider
import Terms from "./Terms";
import Cart from "./Cart";
import PrivacyPolicy from "./Privacy";
import Checkout from "./Checkout";
import CustomCheckout from "./CustomCheckout";
import PaymentDone from "./PaymentDone";
import Player from "./Player";

function App() {
  const [ageVerified, setAgeVerified] = useState(false);

  // Načítanie stavu overenia veku z localStorage
  useEffect(() => {
    const isVerified = localStorage.getItem('ageVerified');
    if (isVerified === 'true') {
      setAgeVerified(true);
    }
  }, []);

  // Funkcia na overenie veku
  const handleAccept = () => {
    localStorage.setItem('ageVerified', 'true');
    setAgeVerified(true);
  };

  const handleReject = () => {
    window.location.href = 'https://www.google.com'; // Presmerovanie na Google
  };

  return (
    <Router>
      <AuthProvider>
        <CartProvider> {/* Obalíme celú aplikáciu CartProviderom */}
          <ScrollToTop /> {/* Zabezpečenie posunutia stránky na vrch pri každom prepnutí route */}
          
          <div className="App">
            {/* Zobrazenie AgeVerificationPopup iba ak nebol vek overený */}
            {!ageVerified && (
              <AgeVerificationPopup onAccept={handleAccept} onReject={handleReject} />
            )}
            
            {ageVerified && (
              <>
                <header className="App-header">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    {/* Route pre Login */}
                    <Route path="/auth/login" element={<LoginForm />} />
                    
                    {/* Route pre Vytvorenie účtu */}
                    <Route path="/auth/create-account" element={<SignUpForm />} />
                    
                    {/* Route pre používateľský Dashboard */}
                    <Route path="/user/dashboard" element={<Dashboard />} />
                    
                    {/* Route pre používateľský profil */}
                    <Route path="/user/profile/:id" element={<Profile />} />
                    
                    {/* Route pre používateľské nastavenia */}
                    <Route path="/user/settings" element={<Settings />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout physical_product={true} />} />
                    <Route path="/custom-checkout/:product_id" element={<CustomCheckout />} />
                    <Route path="/payment-done" element={<PaymentDone />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/player/:recordId" element={<Player />} />
                    
                    {/* Route pre not-found stránku */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </header>
              </>
            )}
          </div>
        </CartProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
