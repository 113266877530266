import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { CheckCircle, ThumbsUp, ThumbsDown } from 'react-feather'; // Icons for like and unlike
import Layout from './components/Layout';
import NotFound from './components/NotFound';
import { Link } from 'react-router-dom';
import Loading from './components/Loading';

// Initialize PocketBase
const pb = new PocketBase('https://eerotika.sk');

const Profile = () => {
  const { id } = useParams(); // Get user ID from the URL
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]); // Store user's products
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null); // Store the current logged-in user
  const [hasLiked, setHasLiked] = useState(false); // Track if the current user has liked this profile
  const [likeRecordId, setLikeRecordId] = useState(null); // Store the ID of the like record (for unliking)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch logged-in user (if any)
        const authUser = pb.authStore.model;
        setCurrentUser(authUser);

        // Fetch the profile user (the one being viewed)
        const userResult = await pb.collection('users').getOne(id);
        setUser(userResult);

        // Fetch user products by owner ID
        const productsResult = await pb.collection('user_products').getFullList({
          filter: `owner="${id}"`, // Filter products by owner (user ID)
        });
        setProducts(productsResult);

        // Check if the current user or localStorage has already liked this profile
        if (authUser) {
          const likeResult = await pb.collection('likes').getFullList({
            filter: `from="${authUser.id}" && to="${id}"`, // Check if the like exists
          });

          if (likeResult.length > 0) {
            setHasLiked(true);
            setLikeRecordId(likeResult[0].id); // Store the like record ID for unliking
          }
        } else {
          // Check if the profile has been liked in localStorage for not logged-in users
          const likedProfiles = JSON.parse(localStorage.getItem('likedProfiles')) || [];
          if (likedProfiles.includes(id)) {
            setHasLiked(true);
          }
        }
      } catch (error) {
        console.error('Error fetching user or products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  // Function to handle liking a user
  const handleLike = async () => {
    if (currentUser) {
      // Logged-in user logic
      try {
        // Create the like record
        const data = {
          from: currentUser.id, // The user giving the like
          to: user.id // The user receiving the like
        };
        const newLike = await pb.collection('likes').create(data);

        // Increment the likes field for the user
        await pb.collection('users').update(user.id, {
          likes: user.likes + 1
        });

        // Update the local state with the new number of likes
        setUser((prevUser) => ({
          ...prevUser,
          likes: prevUser.likes + 1
        }));

        setHasLiked(true);
        setLikeRecordId(newLike.id); // Store the ID for unliking
      } catch (error) {
        console.error('Error liking profile:', error);
      }
    } else {
      // Not logged-in user logic (localStorage)
      const likedProfiles = JSON.parse(localStorage.getItem('likedProfiles')) || [];
      likedProfiles.push(id);
      localStorage.setItem('likedProfiles', JSON.stringify(likedProfiles));

      try {
        // Update the likes field for the user in PocketBase
        await pb.collection('users').update(user.id, {
          likes: user.likes + 1
        });

        // Update the like count locally
        setUser((prevUser) => ({
          ...prevUser,
          likes: prevUser.likes + 1
        }));
      } catch (error) {
        console.error('Error updating likes for the profile:', error);
      }

      setHasLiked(true);
    }
  };

  // Function to handle unliking a user
  const handleUnlike = async () => {
    if (currentUser && likeRecordId) {
      // Logged-in user logic
      try {
        // Delete the like record
        await pb.collection('likes').delete(likeRecordId);

        // Decrement the likes field for the user
        await pb.collection('users').update(user.id, {
          likes: user.likes - 1
        });

        // Update the local state with the new number of likes
        setUser((prevUser) => ({
          ...prevUser,
          likes: prevUser.likes - 1
        }));

        setHasLiked(false);
        setLikeRecordId(null); // Reset the like record ID
      } catch (error) {
        console.error('Error unliking profile:', error);
      }
    } else {
      // Not logged-in user logic (localStorage)
      const likedProfiles = JSON.parse(localStorage.getItem('likedProfiles')) || [];
      const updatedLikes = likedProfiles.filter((profileId) => profileId !== id);
      localStorage.setItem('likedProfiles', JSON.stringify(updatedLikes));

      try {
        // Decrement the likes field for the user in PocketBase
        await pb.collection('users').update(user.id, {
          likes: user.likes - 1
        });

        // Update the like count locally
        setUser((prevUser) => ({
          ...prevUser,
          likes: prevUser.likes - 1
        }));
      } catch (error) {
        console.error('Error updating likes for the profile:', error);
      }

      setHasLiked(false);
    }
  };

  if (loading) {
    return <Loading isLoading={true} />;
  }

  if (!user) {
    return <NotFound />;
  }

  return (
    <Layout>
      <section className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="bg-gray-800 p-6 rounded-lg text-white shadow-lg">
          <div className="flex flex-col sm:flex-row items-center mb-6 space-y-4 sm:space-y-0 sm:space-x-6">
            <img
              src={user.avatar ? `https://eerotika.sk/api/files/_pb_users_auth_/${user.id}/${user.avatar}` : 'https://via.placeholder.com/150'}
              alt={user.username}
              className="w-24 h-24 object-cover rounded-full border-4 border-gray-600 sm:w-28 sm:h-28"
            />
            <div className="text-center sm:text-left">
              <h1 className="text-3xl sm:text-4xl font-bold flex items-center justify-center sm:justify-start">
                {user.username}
                {user.verified && <CheckCircle className="ml-2 text-green-500" title="Verified" />}
              </h1>
              <p className="text-gray-400 mt-2">Likes: {user.likes}</p>

              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center justify-center"
                onClick={hasLiked ? handleUnlike : handleLike}
              >
                {hasLiked ? (
                  <>
                    <ThumbsDown className="mr-2" /> Unlike this profile
                  </>
                ) : (
                  <>
                    <ThumbsUp className="mr-2" /> Like this profile
                  </>
                )}
              </button>
            </div>
          </div>
          <p className="mt-4 text-gray-300 text-center sm:text-left">{user.description}</p>
        </div>
      </section>

      <section id="user-products" className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-white mb-6">Produkty používateľa</h2>
        {products.length > 0 ? (
          <div className="space-y-6">
            {products.map((product) => (
              <div
                key={product.id}
                className="bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105 flex flex-col sm:flex-row items-start sm:items-center"
              >
                <div className="w-full sm:w-1/3 h-48 bg-gray-700 rounded-lg overflow-hidden flex items-center justify-center mb-4 sm:mb-0 sm:mr-6">
                  {product.is_locked ? (
                    <p className="text-white font-bold">Odomknutie po zaplateni</p>
                  ) : (
                    <img
                      src={product.photo ? `https://eerotika.sk/api/files/${product.collectionId}/${product.id}/${product.photo}` : 'https://via.placeholder.com/150'}
                      alt={product.name}
                      className="max-h-full max-w-full object-cover"
                    />
                  )}
                </div>

                <div className="flex-grow w-full sm:w-auto">
                  <h3 className="text-lg font-bold text-white mb-2">{product.name}</h3>
                  <p className="text-gray-400 text-sm mb-4">{product.description}</p>

                  <div className="flex justify-between items-center w-full">
                    <p className="text-red-500 font-bold text-lg">
                      {new Intl.NumberFormat('sk-SK', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(product.price)}
                    </p>

                    <div className="ml-auto sm:ml-4">
                      <Link
                        to={`/custom-checkout/${product.id}`}
                        className={'bg-red-500 text-white px-6 py-2 rounded-full shadow-lg font-semibold transition duration-300 ease-in-out'}
                      >
                        Kúpiť teraz
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-400 text-center">Tento používateľ nemá žiadne produkty.</p>
        )}
      </section>
    </Layout>
  );
};

export default Profile;
