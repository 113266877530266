import React, { useState, useEffect, useContext } from 'react';
import PocketBase from 'pocketbase';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { Heart, ShoppingCart } from 'react-feather'; // Import cart icon
import { Link } from 'react-router-dom';
import Layout from './components/Layout';
import { CartContext } from './components/CartContext'; // Import CartContext
import Lightbox from 'react-image-lightbox'; // Assuming you're using react-image-lightbox
import 'react-image-lightbox/style.css'; // Don't forget the Lightbox styles

// Initialize PocketBase with your server URL
const pb = new PocketBase("https://eerotika.sk");

const Home = () => {
  const [products, setProducts] = useState([]); // Store products
  const [category, setCategory] = useState(''); // Store the selected category
  const [loading, setLoading] = useState(false); // Loading state
  const [isUsedCategory, setIsUsedCategory] = useState(false); // State to handle used category
  const [lightboxOpen, setLightboxOpen] = useState(false); // State for lightbox
  const [currentImages, setCurrentImages] = useState([]); // Images for the current product
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Current image index for lightbox

  const { addToCart, cart } = useContext(CartContext); // Use addToCart and cart from CartContext


  const scrollUsed = async () => {
    const abortController = new AbortController();
    try {
      const result = await pb.collection("user_products").getFullList({
        expand: 'owner',
        perPage: 100,
        signal: abortController.signal, // Add abortController to the request
      });
  
      const uniqueUsersMap = {};
      result.forEach((product) => {
        const owner = product.expand.owner;
        if (owner && !uniqueUsersMap[owner.id]) {
          uniqueUsersMap[owner.id] = {
            id: owner.id, // Store user id for profile link
            username: owner.username,
            avatar: owner.avatar ? `https://eerotika.sk/api/files/users/${owner.id}/${owner.avatar}` : 'https://picsum.photos/200',
            likes: owner.likes, // Assuming 'likes' field exists in the user schema
          };
        }
      });
  
      // Convert to array and sort by 'likes' in descending order
      const sortedUsers = Object.values(uniqueUsersMap).sort((a, b) => b.likes - a.likes);
  
      setProducts(sortedUsers); // Set the sorted products
      setIsUsedCategory(true);
      scroller.scrollTo('products', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log("Request was cancelled");
      } else {
        console.error("Error fetching products:", error);
      }
    }
    
    // Clean up function to cancel the request if component unmounts
    return () => abortController.abort();
  };
  
  
  const fetchProducts = async (category) => {
    setLoading(true);
    try {
      if (category === 'used') {
        const result = await pb.collection("user_products").getFullList({
          expand: 'owner',
          perPage: 100,
        });
      
        const uniqueUsersMap = {};
        result.forEach((product) => {
          const owner = product.expand.owner;
          if (owner && !uniqueUsersMap[owner.id]) {
            uniqueUsersMap[owner.id] = {
              id: owner.id, // Store user id for profile link
              username: owner.username,
              avatar: owner.avatar ? `https://eerotika.sk/api/files/users/${owner.id}/${owner.avatar}` : 'https://picsum.photos/200',
              likes: owner.likes, // Assuming 'likes' field exists in the user schema
            };
          }
        });
      
        // Convert to array and sort by 'likes' in descending order
        const sortedUsers = Object.values(uniqueUsersMap).sort((a, b) => b.likes - a.likes);
      
        setProducts(sortedUsers); // Set the sorted products
        setIsUsedCategory(true);
      }
       else {
        const result = await pb.collection("products").getFullList({
          filter: `category='${category}'`,
        });
        setProducts(result);
        setIsUsedCategory(false);
      }

      scroller.scrollTo('products', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });

    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (category) {
      fetchProducts(category);
    }
  }, [category]);

  // Open lightbox with selected images
  const openLightbox = (images, index) => {
    setCurrentImages(images);
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => setLightboxOpen(false);

  return (
    <Layout>
      {/* Hero Section */}
      <section
        className="bg-red-600 py-20 text-center"
        style={{
          backgroundImage: `url('https://eerotika.sk/att.XExdme00iNOEyeAZCSTqLn2CPhMMzDPre4h_LBT6Gtc.jpg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <h2 className="text-5xl font-extrabold">Vitajte na eErotika</h2>
        <p className="mt-4 text-xl">Objavte najlepšie kúsky pre mužov, ženy a second-hand produkty s dopravou vždy zdarma.</p>
        <button onClick={scrollUsed} className="mt-8 inline-block bg-black text-white px-6 py-3 rounded-lg text-lg hover:bg-gray-800">
          Nakupovať teraz
        </button>
        <div className="absolute top-4 right-4 flex items-center">
          <ShoppingCart className="text-white" />
          <span className="ml-2 text-white text-lg font-bold">
            {cart.length} položky v košíku
          </span>
        </div>
      </section>

      {/* Categories Section */}
      <section className="max-w-7xl mx-auto py-16">
        <h3 className="text-4xl font-bold text-center mb-12">Kategórie</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <button
            className="relative block group"
            onClick={() => setCategory('male')}
          >
            <img
              src="https://eerotika.sk/men_category.jpg"
              alt="Mužské"
              className="w-full h-64 object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center group-hover:opacity-100 transition-opacity">
              <h4 className="text-3xl font-bold text-white">Mužské</h4>
            </div>
          </button>

          <button
            className="relative block group"
            onClick={() => setCategory('female')}
          >
            <img
              src="https://eerotika.sk/women_category.jpg"
              alt="Ženské"
              className="w-full h-64 object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center group-hover:opacity-100 transition-opacity">
              <h4 className="text-3xl font-bold text-white">Ženské</h4>
            </div>
          </button>

          <button
            className="relative block group"
            onClick={() => setCategory('used')}
          >
            <img
              src="https://eerotika.sk/used_category.jpg"
              alt="Použité"
              className="w-full h-64 object-cover rounded-lg"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center group-hover:opacity-100 transition-opacity">
              <h4 className="text-3xl font-bold text-white">Použité</h4>
            </div>
          </button>
        </div>
      </section>

      {/* Products/Users Section */}
      <section id="products" name="products" className="max-w-7xl mx-auto py-16">
  <h3 className="text-4xl font-bold text-center mb-12">
    {isUsedCategory ? 'Používatelia s produktami' : 'Naša Ponuka'}
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
    {loading ? (
      <p className="text-center">Načítavam...</p>
    ) : products.length > 0 ? (
      isUsedCategory ? (
        products.map((user, index) => (
          <div key={index} className="bg-gray-800 p-6 rounded-lg text-center">
            <img
              src={user.avatar ? user.avatar : 'https://picsum.photos/200'}
              alt={user.username}
              className="w-36 h-36 object-cover mx-auto mb-4 rounded-lg"
            />
            <h4 className="text-2xl font-bold mb-2">{user.username}</h4>
            <div className="flex items-center justify-center text-gray-300 mb-4">
              <Heart className="text-red-600 mr-2" />
              <span className="text-lg">{user.likes} Likes</span>
            </div>
            <Link to={`/user/profile/${user.id}`} className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700">
              Navštíviť profil
            </Link>
          </div>
        ))      
      ) : (
        products.map((product) => (
          <div key={product.id} className="bg-gray-800 p-6 rounded-lg relative">
            {/* Adjusted Image to display full without cropping */}
            <div className="w-full h-auto flex items-center justify-center mb-4">
              <img
                src={product.image ? `https://eerotika.sk/api/files/${product.collectionId}/${product.id}/${product.image[0]}` : 'https://picsum.photos/300/200'}
                alt={product.title}
                className="w-full object-contain rounded-lg"
              />
            </div>
            
            <h4 className="text-2xl font-bold mb-2">{product.title}</h4>
            <p className="text-gray-400 mb-4">{product.description}</p>
        
            <div className="flex justify-between items-center mb-4">
              {/* Price aligned left */}
              <span className="text-xl text-red-600 font-bold">
                {new Intl.NumberFormat('sk-SK', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(product.price)}
              </span>
              
              {/* Button aligned right */}
              <button
                className="ml-auto bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
                onClick={() => addToCart(product)}
              >
                Pridať do košíka
              </button>
            </div>
        
            {product.image && product.image.length > 1 && (
              <div className="flex justify-center mt-4">
                <button
                  className="bg-gray-600 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition-all duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-gray-400"
                  onClick={() =>
                    openLightbox(
                      product.image.map((img) => ({
                        collectionId: product.collectionId,
                        id: product.id,
                        image: img,
                      })),
                      0
                    )
                  }
                >
                  Zobraziť viac fotiek
                </button>
              </div>
            )}
          </div>
        ))
      )
    ) : (
      <p className="text-center">Žiadne výsledky pre zvolenú kategóriu.</p>
    )}
  </div>
</section>


      {/* Lightbox for Product Images */}
      {lightboxOpen && currentImages.length > 0 && (
        <Lightbox
          mainSrc={`https://eerotika.sk/api/files/${currentImages[currentImageIndex].collectionId}/${currentImages[currentImageIndex].id}/${currentImages[currentImageIndex].image}`}
          nextSrc={`https://eerotika.sk/api/files/${currentImages[(currentImageIndex + 1) % currentImages.length].collectionId}/${currentImages[(currentImageIndex + 1) % currentImages.length].id}/${currentImages[(currentImageIndex + 1) % currentImages.length].image}`}
          prevSrc={`https://eerotika.sk/api/files/${currentImages[(currentImageIndex + currentImages.length - 1) % currentImages.length].collectionId}/${currentImages[(currentImageIndex + currentImages.length - 1) % currentImages.length].id}/${currentImages[(currentImageIndex + currentImages.length - 1) % currentImages.length].image}`}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImageIndex((currentImageIndex + currentImages.length - 1) % currentImages.length)
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % currentImages.length)
          }
        />
      )}
    </Layout>
  );
};

export default Home;
