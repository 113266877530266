import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./Auth";
import PocketBase from "pocketbase";
import Layout from "./components/Layout";
import Loading from "./components/Loading";

// Initialize PocketBase with your server URL
const pb = new PocketBase("https://eerotika.sk");

const Dashboard = () => {
  const { user: authUser } = useAuth();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);  // Pridáme stav pre načítavanie
  const [balance, setBalance] = useState(0);
  const [showIbanModal, setShowIbanModal] = useState(false);
  const [iban, setIban] = useState("");
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    virtual: false,
  });
  const [file, setFile] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authUser) {
      navigate("/auth/login");
    } else {
      setIsLoading(true); // Zobraziť loading pri fetchovaní údajov
      const fetchUserBalance = async () => {
        try {
          const user = await pb.collection("users").getOne(authUser.id);
          setBalance(user.balance);
          setIban(user.iban || "");

          if (!user.iban) {
            setShowIbanModal(true);
          }
        } catch (error) {
          console.error("Error fetching user balance:", error);
        } finally {
          setIsLoading(false); // Skryť loading po načítaní údajov
        }
      };

      fetchUserBalance();
    }
  }, [authUser, navigate]);

  useEffect(() => {
    setIsLoading(true); // Zobraziť loading pred načítaním produktov
    const fetchUserProducts = async () => {
      if (authUser && authUser.id) {
        try {
          const result = await pb.collection("user_products").getFullList({
            filter: `owner.id = "${authUser.id}"`,
          });
          setProducts(result);
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
          setIsLoading(false); // Skryť loading po načítaní produktov
        }
      } else {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchUserProducts();
  }, [authUser]);

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Zobraziť loading pri vytváraní produktu

    const data = {
      ...newProduct,
      owner: authUser.id,
    };

    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("price", data.price);
      formData.append("virtual", data.virtual);
      formData.append("owner", data.owner);

      if (!newProduct.virtual) {
        if (photo) {
          formData.append("photo", photo);
        } else {
          alert("You must upload a photo.");
          return;
        }
      }

      if (data.virtual && file) {
        formData.append("file", file);
      }

      const record = await pb.collection("user_products").create(formData);
      setProducts((prev) => [...prev, record]);
      setNewProduct({ name: "", description: "", price: "", virtual: false });
      setFile(null);
      setPhoto(null);
      setShowCreateModal(false);
    } catch (error) {
      console.error("Error creating product:", error);
    } finally {
      setIsLoading(false); // Skryť loading po vytvorení produktu
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProduct((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle file input change for virtual file
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle photo input change (always required)
  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleEditProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Zobraziť loading pri editovaní produktu

    try {
      const updatedProduct = await pb.collection("user_products").update(editProduct.id, {
        name: editProduct.name,
      });
      setProducts((prev) =>
        prev.map((product) => (product.id === updatedProduct.id ? updatedProduct : product))
      );
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setIsLoading(false); // Skryť loading po úprave produktu
    }
  };

  const handleDeleteProduct = async () => {
    setIsLoading(true); // Zobraziť loading pri mazaní produktu

    try {
      await pb.collection("user_products").delete(productToDelete.id);
      setProducts((prev) => prev.filter((product) => product.id !== productToDelete.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting product:", error);
    } finally {
      setIsLoading(false); // Skryť loading po odstránení produktu
    }
  };


  if (loading) {
    return <p>Loading your products...</p>;
  }

  return (
    <Layout>
      <Loading isLoading={isLoading} />  {/* Zobrazí loading komponent */}
      <div className="max-w-7xl mx-auto py-16 px-4">
        <h1 className="text-4xl font-bold text-red-600 mb-8">Vitajte v dashboarde</h1>

        {/* Display balance */}
        <div className="mb-8">
          <h2 className="text-3xl font-semibold text-white mb-2">Váš zostatok v peňaženke</h2>
          <p className="text-2xl text-green-500 font-bold">
            {new Intl.NumberFormat('sk-SK', {
              style: 'currency',
              currency: 'EUR',
            }).format(balance)}
          </p>
        </div>

        <button
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => setShowCreateModal(true)}
        >
          Vytvoriť nový produkt
        </button>

        <div className="mb-12 mt-8">
          <h2 className="text-3xl font-semibold text-white mb-4">Vaše produkty</h2>
          {products.length > 0 ? (
            <ul className="space-y-4">
              {products.map((product) => (
                <li key={product.id} className="bg-gray-800 p-4 rounded-lg flex justify-between items-center">
                  <div>
                    <strong className="text-xl text-white">{product.name}</strong>:
                    <span className="text-gray-400"> {product.description} - </span>
                    <span className="text-red-500 font-bold">
                      {new Intl.NumberFormat('sk-SK', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(product.price)}
                    </span>
                  </div>
                  <div className="flex space-x-4">
                    <button
                      className="text-blue-500 hover:underline"
                      onClick={() => {
                        setEditProduct(product);
                        setShowEditModal(true);
                      }}
                    >
                      Premenovať
                    </button>
                    <button
                      className="text-red-500 hover:underline"
                      onClick={() => {
                        setProductToDelete(product);
                        setShowDeleteModal(true);
                      }}
                    >
                      Odstrániť
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-400">Zatiaľ nemáte žiadne produkty.</p>
          )}
        </div>
    {/* Create Product Modal */}
    {showCreateModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-white mb-4">Vytvoriť nový produkt</h2>
              <form onSubmit={handleCreateProduct}>
                <input
                  type="text"
                  name="name"
                  placeholder="Názov produktu"
                  value={newProduct.name}
                  onChange={handleInputChange}
                  className="w-full p-3 rounded-md bg-gray-700 text-white mb-4"
                  required
                />
                <input
                  type="text"
                  name="description"
                  placeholder="Popis produktu"
                  value={newProduct.description}
                  onChange={handleInputChange}
                  className="w-full p-3 rounded-md bg-gray-700 text-white mb-4"
                  required
                />
                <input
                  type="number"
                  name="price"
                  placeholder="Cena"
                  value={newProduct.price}
                  onChange={handleInputChange}
                  className="w-full p-3 rounded-md bg-gray-700 text-white mb-4"
                  required
                />
                <label className="flex items-center space-x-2 text-white mb-4">
                  <input
                    type="checkbox"
                    name="virtual"
                    checked={newProduct.virtual}
                    onChange={handleInputChange}
                    className="rounded"
                  />
                  <span>Virtuálny produkt</span>
                </label>

                {newProduct.virtual && (
                  <div>
                    <label className="block text-white mb-2">Nahrajte súbor (video/obrázok):</label>
                    <input
                      type="file"
                      accept="image/*,video/*"
                      name="file"
                      onChange={handleFileChange}
                      className="w-full p-2 rounded-md bg-gray-700 text-white mb-4"
                    />
                  </div>
                )}

                  <div>
                    {!newProduct.virtual && (
                      <div>
                        <label className="block text-white mb-2">Nahrajte fotku produktu (povinné):</label>
                        <input
                          type="file"
                          name="photo"
                          onChange={handlePhotoChange}
                          accept="image/*"
                          required
                          className="w-full p-2 rounded-md bg-gray-700 text-white mb-4"
                        />
                      </div>
                    )}
                  </div>

                <button
                  type="submit"
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 rounded-md transition duration-300"
                >
                  Vytvoriť produkt
                </button>
                <button
                  type="button"
                  className="w-full mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-3 rounded-md transition duration-300"
                  onClick={() => setShowCreateModal(false)}
                >
                  Zatvoriť
                </button>
              </form>
            </div>
          </div>
        )}

        {/* Edit Product Modal */}
        {showEditModal && editProduct && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-white mb-4">Premenovať produkt</h2>
              <form onSubmit={handleEditProduct}>
                <input
                  type="text"
                  value={editProduct.name}
                  onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
                  className="w-full p-3 rounded-md bg-gray-700 text-white mb-4"
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 rounded-md transition duration-300"
                >
                  Uložiť
                </button>
                <button
                  type="button"
                  className="w-full mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-3 rounded-md transition duration-300"
                  onClick={() => setShowEditModal(false)}
                >
                  Zatvoriť
                </button>
              </form>
            </div>
          </div>
        )}


        {showIbanModal && (
          <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">IBAN Potvrdenie</h2>
              <p className="text-gray-600 mb-6">
                Aby ste mohli dostávať výplaty, musíte vyplniť svoj IBAN v nastaveniach.
                Každý týždeň dostanete peniaze vždy v pondelok. Zároveň si berieme 
                <span className="text-red-500 font-semibold"> 10% províziu </span>
                a <span className="text-red-500 font-semibold"> 20% DPH </span> a vám príde čistý zisk.
              </p>
              <button
                className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 rounded-lg transition duration-300"
                onClick={() => {
                  setShowIbanModal(false);
                  navigate('/user/settings'); // Presmerovanie na nastavenia užívateľa
                }}
              >
                Súhlasím
              </button>
            </div>
          </div>
        )}



        {/* Delete Confirmation Modal */}
        {showDeleteModal && productToDelete && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-white mb-4">Ste si istí, že chcete odstrániť tento produkt?</h2>
              <p className="text-gray-400 mb-6">Tento krok je nevratný.</p>
              <button
                onClick={handleDeleteProduct}
                className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 rounded-md transition duration-300 mb-4"
              >
                Odstrániť
              </button>
              <button
                type="button"
                className="w-full bg-gray-600 hover:bg-gray-700 text-white font-bold py-3 rounded-md transition duration-300"
                onClick={() => setShowDeleteModal(false)}
              >
                Zatvoriť
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
