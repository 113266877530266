import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { CartContext } from './components/CartContext';
import Layout from './components/Layout';
import CardComponent from './components/CardComponent';
import Loading from './components/Loading'; // Import Loading component
import PocketBase from 'pocketbase'; // Import PocketBase client

const stripePromise = loadStripe('pk_live_51Q0RiFLt4eCpxGB2FRE4IsMvlwyAxUky2M819OaVym3eBwJI0aJ9Gko4FuJNxmkm7m5ItmnjMxm1723SPs1exrqf00SzN4QdUP', {
  locale: 'sk',
});

const pb = new PocketBase('https://eerotika.sk'); // Initialize PocketBase client

const CheckoutForm = () => {
  const { totalPrice, cart, clearCart } = useContext(CartContext); // Use clearCart instead of setCart
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();
  const physical_product = location.state?.physical_product || true;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [loading, setLoading] = useState(false); // Control loading state
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if (!stripe || !elements) return;
  
    const paymentData = {
      amount: totalPrice * 100, // Amount in cents
      email,
      name,
      address: {
        line1: addressLine1,
        city,
        country,
        postal_code: postalCode,
      },
      cart,
    };
  
    try {
      // Step 1: Create payment intent
      const response = await fetch('https://api.eerotika.sk/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentData),
      });
  
      if (!response.ok) {
        throw new Error('Chyba pri vytváraní platby.');
      }
  
      const { clientSecret } = await response.json();
      const paymentIntentId = clientSecret.split('_secret')[0]; // Extract PaymentIntent ID
  
      // Step 2: Confirm the card payment using Stripe.js
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
            email,
            address: {
              line1: addressLine1,
              city,
              country,
              postal_code: postalCode,
            },
          },
        },
      });
  
      if (paymentResult.error) {
        setError(`${paymentResult.error.message}`);
        window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
      } else {
        // Step 3: Always verify payment status via backend, even after a Stripe success
        await verifyPaymentStatus(paymentIntentId);
      }
    } catch (err) {
      setError('Platba zlyhala. Skúste to znova.');
    } finally {
      setLoading(false);
    }
  };
  
  // Function to check payment status by calling the backend
  const verifyPaymentStatus = async (paymentIntentId) => {
    try {
      const response = await fetch('https://api.eerotika.sk/check-payment-status', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payment_intent_id: paymentIntentId }),
      });
  
      if (!response.ok) {
        throw new Error('Chyba pri overovaní platby.');
      }
  
      const result = await response.json();
  
      switch (result.status) {
        case 'succeeded':
          // Payment was successful
          await addOrderToPocketBase(); // Add order to PocketBase collection
          clearCart();  // Use clearCart to empty the cart
          window.location.replace('/payment-done');
          break;
        case 'requires_action':
          setError('Platba vyžaduje ďalšiu autentifikáciu. Skúste to znova.');
          break;
        case 'processing':
          setError('Platba je spracovávaná, prosím čakajte.');
          break;
        case 'requires_payment_method':
          setError('Platba zlyhala. Skúste použiť inú kartu.');
          break;
        default:
          setError('Platba zlyhala. Neznámy problém.');
          break;
      }
    } catch (err) {
      setError(`Chyba pri overovaní platby: ${err.message}`);
    }
  };

  // Function to add order details to PocketBase collection
  const addOrderToPocketBase = async () => {
    const products = cart.map((item) => ({
      id: item.id, // Product ID
      name: item.name, // Product name (if needed)
      quantity: item.quantity // Quantity of the product
    }));

    const data = {
      customer_name: name,
      address: addressLine1,
      city,
      zip_code: postalCode,
      paid: true,
      amount: totalPrice,
      country,
      products: JSON.stringify(products), // Convert the products array to a JSON string
    };

    try {
      const record = await pb.collection('orders').create(data);
      console.log('Order added successfully:', record);
    } catch (error) {
      console.error('Error adding order to PocketBase:', error);
    }
  };

  
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <Loading isLoading={loading} /> {/* Display the loading spinner */}

      {error && <p className="text-red-600">{error}</p>}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Zadajte váš email"
          className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
        />
      </div>

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-white">Meno a priezvisko</label>
        <input
          id="name"
          type="text"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Zadajte vaše meno a priezvisko"
          className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
        />
      </div>

      {physical_product && (
        <>
          <div>
            <label htmlFor="addressLine1" className="block text-sm font-medium text-white">Dodacia adresa (riadok 1)</label>
            <input
              id="addressLine1"
              type="text"
              required
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              placeholder="Zadajte adresu"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>

          <div>
            <label htmlFor="city" className="block text-sm font-medium text-white">Mesto</label>
            <input
              id="city"
              type="text"
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Zadajte vaše mesto"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>

          <div>
            <label htmlFor="country" className="block text-sm font-medium text-white">Krajina</label>
            <select
              id="country"
              required
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            >
              <option value="">Vyberte krajinu</option>
              <option value="SK">Slovensko</option>
              <option value="CZ">Česko</option>
            </select>
          </div>

          <div>
            <label htmlFor="postalCode" className="block text-sm font-medium text-white">PSČ</label>
            <input
              id="postalCode"
              type="text"
              required
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              placeholder="Zadajte PSČ"
              className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
          </div>
        </>
      )}

      {/* CardComponent */}
      <CardComponent />

      <div>
        <p className="text-lg text-white">
          Celková suma: {totalPrice.toLocaleString('sk-SK', { style: 'currency', currency: 'EUR' })}
        </p>
      </div>

      <button
        type="submit"
        disabled={loading || !stripe}
        className={`w-full py-2 text-center rounded-md font-semibold ${loading ? 'bg-gray-600' : 'bg-red-600 hover:bg-red-700'} transition duration-300 ease-in-out`}
      >
        {loading ? 'Spracováva sa...' : 'Zaplatiť teraz'}
      </button>
    </form>
  );
};

const Checkout = ({ physical_product }) => (
  <Layout>
    <div className="max-w-md mx-auto mt-8 p-6 bg-gray-900 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white mb-4">Platba</h2>
      <Elements stripe={stripePromise}>
        <CheckoutForm physical_product={physical_product} />
      </Elements>
    </div>
  </Layout>
);

export default Checkout;
