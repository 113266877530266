import React, { useState, useEffect, useContext } from 'react';
import { User, Menu, X, ShoppingCart, ArrowUp } from 'react-feather';
import { useAuth } from '../Auth';
import { Link } from 'react-router-dom';
import { CartContext } from './CartContext';

const Layout = ({ children }) => {
  const { loggedIn, logOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false); // State to toggle cart display
  const { cart, totalPrice, createPayment } = useContext(CartContext); // Use cart context
  const [showScrollButton, setShowScrollButton] = useState(false); // State to toggle scroll button visibility


  // Toggle dropdown menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {  // Tu môžeš zmeniť číslo 300
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Toggle mobile menu visibility
  const toggleMobileMenu = (e) => {
    e.stopPropagation();
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Toggle cart visibility
  const toggleCart = (e) => {
    e.stopPropagation();
    setIsCartOpen(!isCartOpen);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white" onClick={() => { setIsMenuOpen(false); setIsCartOpen(false); }}>
      {/* Header */}
      <header className="sticky top-0 bg-black z-50 shadow-md">
        <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/"><h1 className="text-3xl font-bold text-red-600">eErotika</h1></Link>

          <div className="hidden md:flex space-x-6 items-center ml-auto">
            <Link to="/" className="hover:text-red-600">Domov</Link>

            {/* Conditionally render user menu or login options */}
            {loggedIn ? (
              <div className="relative">
                <button onClick={(e) => { e.stopPropagation(); toggleMenu(); }} className="focus:outline-none">
                  <User className="hover:text-red-600" />
                </button>

                {isMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50">
                    <div className="py-2">
                      <Link
                        to="/user/dashboard"
                        className="block px-4 py-2 text-sm text-white hover:bg-gray-700"
                        onClick={toggleMenu}
                      >
                        Dashboard
                      </Link>
                      <Link
                        to="/user/settings"
                        className="block px-4 py-2 text-sm text-white hover:bg-gray-700"
                        onClick={toggleMenu}
                      >
                        Nastavenia
                      </Link>
                      <button
                        onClick={() => { logOut(); toggleMenu(); }}
                        className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
                      >
                        Odhlásiť sa
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link to="/auth/login" className="hover:text-red-600">Prihlásiť sa</Link>
                <Link to="/auth/create-account" className="hover:text-red-600">Vytvoriť účet</Link>
              </>
            )}

            {/* Cart Icon */}
            <div className="relative">
              <button onClick={toggleCart} className="relative focus:outline-none">
                <ShoppingCart className="hover:text-red-600 h-6 w-6" />
                {cart.length > 0 && (
                  <>
                    <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full h-4 w-4 text-xs flex items-center justify-center">
                      {cart.reduce((total, item) => total + item.quantity, 0)}
                    </span>
                    <span className="ml-2 text-xs">€{totalPrice.toFixed(2)}</span>
                  </>
                )}
              </button>

              {/* Cart dropdown */}
              {isCartOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-gray-800 rounded-md shadow-lg z-50 p-3">
                  {cart.length > 0 ? (
                    <ul className="space-y-2">
                      {cart.map((item, index) => (
                        <li key={index} className="flex justify-between items-center">
                          <div>
                            <h4 className="text-xs font-bold">{item.title}</h4>
                            <p className="text-xs text-gray-400">{item.quantity}x {item.price.toFixed(2).replace('.', ',')} € = {(item.price * item.quantity).toFixed(2).replace('.', ',')} €</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="text-xs text-gray-400">Košík je prázdny</p>
                  )}
                  <div className="mt-3">
                    <p className="text-xs font-bold">Celkovo: €{totalPrice.toFixed(2)}</p>
                    {cart.length > 0 && (
                    <Link to="/checkout"
                      className="block w-full mt-3 bg-gray-700 text-white py-2 rounded-md text-center text-lg font-semibold hover:bg-gray-600 transition duration-300 ease-in-out shadow-lg"
                    >
                      Prejsť na platbu
                    </Link>
                  )}
                    <Link to="/cart" className="block mt-2 bg-gray-600 text-xs text-white py-1 text-center rounded-md hover:bg-gray-700">
                      Pozrieť celý košík
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Mobile menu with Cart icon */}
          <div className="flex space-x-4 md:hidden">
            {/* Cart Icon for mobile */}
            <div className="relative">
              <button onClick={toggleCart} className="relative focus:outline-none">
                <ShoppingCart className="hover:text-red-600 h-6 w-6" />
                {cart.length > 0 && (
                  <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full h-4 w-4 text-xs flex items-center justify-center">
                    {cart.reduce((total, item) => total + item.quantity, 0)}
                  </span>
                )}
              </button>

              {/* Cart dropdown for mobile */}
              {isCartOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-gray-800 rounded-md shadow-lg z-50 p-3">
                {cart.length > 0 ? (
                  <>
                    <ul className="space-y-2">
                      {cart.map((item, index) => (
                        <li key={index} className="flex justify-between items-center">
                          <div>
                            <h4 className="text-xs font-bold text-white">{item.title}</h4>
                            <p className="text-xs text-gray-400">
                            {item.quantity}x {item.price.toFixed(2).replace('.', ',')} € = {(item.price * item.quantity).toFixed(2).replace('.', ',')} €
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="mt-3">
                      <p className="text-xs font-bold text-white">Celkovo: €{totalPrice.toFixed(2)}</p>
                      <button
                        className="block w-full mt-2 bg-gray-700 text-xs text-white py-1 text-center rounded-md hover:bg-gray-600"
                        onClick={() => window.location.href = '/cart'}
                      >
                        Pozrieť celý košík
                      </button>
                      <button
                        onClick={createPayment}
                        className={`block w-full mt-3 text-xs text-white py-1 text-center rounded-md ${
                          cart.length > 0 ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-500 cursor-not-allowed'
                        }`}
                        disabled={cart.length === 0}
                      >
                        Prejsť na platbu
                      </button>
                    </div>
                  </>
                ) : (
                  <p className="text-xs text-gray-400">Košík je prázdny</p>
                )}
              </div>
            )}
            </div>
            {/* Mobile menu button */}
            <button id="menu-toggle" className="text-white" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-gray-800 py-4 px-4">
            <nav className="space-y-4">
              <Link to="/" className="block text-white hover:text-red-600" onClick={toggleMobileMenu}>Domov</Link>
              <div className="space-y-2">
                {loggedIn ? (
                  <>
                    <Link
                      to="/user/dashboard"
                      className="block text-white hover:text-red-600"
                      onClick={toggleMobileMenu}
                    >
                      Dashboard
                    </Link>
                    <Link
                    to="/user/settings"
                    className="block text-white hover:text-red-600"
                    onClick={toggleMobileMenu}
                    >
                    Nastavenia
                    </Link>
                    <button
                      onClick={() => { logOut(); toggleMobileMenu(); }}
                      className="block text-white hover:text-red-600 w-full text-left"
                    >
                      Odhlásiť sa
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/auth/login"
                      className="block text-white hover:text-red-600"
                      onClick={toggleMobileMenu}
                    >
                      Prihlásiť sa
                    </Link>
                    <Link
                      to="/auth/create-account"
                      className="block text-white hover:text-red-600"
                      onClick={toggleMobileMenu}
                    >
                      Vytvoriť účet
                    </Link>
                  </>
                )}
              </div>
            </nav>
          </div>
        )}
      </header>

      {/* Content */}
      <main className="flex-grow">{children}</main>

      {showScrollButton && ( // Show button only when scrolled down
        <button
          className="fixed bottom-6 right-6 bg-red-600 text-white p-3 rounded-full shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <ArrowUp className="h-6 w-6" />
        </button>
      )}

      {/* Footer */}
      <footer className="bg-black py-12 px-4 md:px-8">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="text-left">
            <h4 className="text-lg font-bold text-white">Kontakt</h4>
            <p className="text-gray-400">info@eerotika.sk</p>
          </div>
          <div className="mt-8 md:mt-0 text-left md:text-right">
            <h4 className="text-lg font-bold text-white">Právne informácie</h4>
            <ul className="text-gray-400">
              <li><Link to="/terms" className="hover:text-red-600">Obchodné podmienky</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-red-600">Ochrana osobných údajov</Link></li>
            </ul>
          </div>
        </div>
        <div className="text-center text-gray-400 mt-12">&copy; 2024 eErotika.sk Všetky práva vyhradené.</div>
      </footer>


    </div>
  );
};

export default Layout;
