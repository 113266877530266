import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

// Define custom styles for the CardElement
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#ffffff',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
    },
  },
  hidePostalCode: true, // Hide the postal code field
};


const CardComponent = () => {
  return (
    <div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-white">Číslo karty</label>
        <div className="w-full p-2 bg-gray-800 border border-gray-700 rounded-md">
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
