import React, { useEffect, useState } from 'react';
import Layout from './components/Layout';

const PaymentDone = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 50 : 100));
    }, 400); // Faster progress bar

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <Layout>
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <div className="text-center space-y-6">
          <h1 className="text-5xl font-extrabold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">
            Platba úspešne dokončená!
          </h1>
          <p className="text-lg font-medium mb-6 text-gray-300">
            Ďakujeme za váš nákup.
          </p>

          {/* Progress Bar */}
          <div className="relative w-72 mx-auto">
            <div className="overflow-hidden h-5 mb-4 text-xs flex rounded-full bg-gray-700 shadow-lg">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-green-400 to-blue-500 transition-all duration-500 ease-in-out rounded-full"
              />
            </div>
            {progress < 100 ? (
              <p className="text-sm text-gray-400">Spracovávanie platby...</p>
            ) : (
              <p className="text-sm text-green-400">Platba úspešne dokončená!</p>
            )}
          </div>

          
        </div>
      </div>
    </Layout>
  );
};

export default PaymentDone;
